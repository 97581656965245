<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HY_list' }">重要会议</el-breadcrumb-item>
      <el-breadcrumb-item>会议详情</el-breadcrumb-item>
    </el-breadcrumb>

    <div id="js_article" style="position:relative;" class="rich_media">

      <div id="js_top_ad_area" class="top_banner"></div>

      <div id="js_base_container" class="rich_media_inner">


        <div id="page-content" class="rich_media_area_primary" style="">
          <div class="rich_media_area_primary_inner">


            <div id="img-content" class="rich_media_wrp">

              <h1 class="rich_media_title " id="activity-name">

                <el-link :href="yourExternalPageURL" class="rich_media_title"
                  target="_blank">自治区放射专业质控中心工作会议及放射质量控制能力提高学习班在新医大一附院举办（转载）</el-link>
              </h1>
              <div id="meta_content" class="rich_media_meta_list">

                <span class="rich_media_meta rich_media_meta_nickname" id="profileBt">
                  <a href="javascript:void(0);" class="wx_tap_link js_wx_tap_highlight weui-wa-hotarea" id="js_name">
                    新医微讯 </a>


                </span>

                <span id="meta_content_hide_info" class="">
                  <em id="publish_time" class="rich_media_meta rich_media_meta_text">2024年07月17日 20:42</em>
                  <em id="js_ip_wording_wrp" class="rich_media_meta rich_media_meta_text" role="option"
                    aria-labelledby="js_a11y_op_ip_wording js_ip_wording" style="display: inline-block;"><span
                      id="js_a11y_op_ip_wording" aria-hidden="true"></span><span aria-hidden="true"
                      id="js_ip_wording">新疆</span></em>

                </span>
              </div>



              <div class="rich_media_content js_underline_content
                       autoTypeSetting24psection
            " id="js_content" style="">
                <section style="font-size: 16px; visibility: visible; margin-bottom: 0px;">
                  <section
                    style="text-align: center; margin-top: 10px; margin-bottom: 10px; line-height: 0; visibility: visible;">
                    <section
                      style="vertical-align: middle; display: inline-block; line-height: 0; visibility: visible;"><img
                        data-imgfileid="502734450" data-ratio="0.15" data-s="300,640" data-type="jpeg" data-w="1080"
                        style="vertical-align: middle; width: 677px !important; height: auto !important; visibility: visible !important;"
                        class="rich_pages wxw-img" data-original-style="vertical-align: middle;width: 100%;"
                        data-index="1" src="@/assets/HY20240724/top.png" _width="100%" crossorigin="anonymous" alt="图片"
                        data-fail="0"></section>
                  </section>
                  <section
                    style="text-align: left; justify-content: flex-start; display: flex; flex-flow: row; margin-top: 10px; margin-bottom: 10px; visibility: visible;">
                    <section
                      style="display: inline-block; width: 100%; vertical-align: top; align-self: flex-start; flex: 0 0 auto; border-style: dashed; border-width: 1px; border-color: rgb(95, 176, 150); background-color: rgba(95, 176, 150, 0.11); padding: 17px; visibility: visible;">
                      <section style="text-align: justify; font-size: 18px; visibility: visible;">
                        <p style="text-wrap: wrap; visibility: visible;">
                          为深化医疗质量管理，规范影像诊疗行为，推动医疗服务规范化，促进医疗质量持续改进，近日，自治区放射专业质控中心工作会议及学习班在新疆医科大学第一附属医院成功举办。</p>
                        <p style="text-wrap: wrap; visibility: visible;"><br style="visibility: visible;"></p>
                      </section>
                      <section style="margin: 0.5em auto; text-align: center; visibility: visible;">
                        <section
                          style="border-radius: 1.5em; display: inline-block; line-height: 0; overflow: hidden; width: 100%; height: auto; visibility: visible;">
                          <img class="rich_pages wxw-img" data-imgfileid="502734452" data-ratio="0.6203703703703703"
                            data-s="300,640" data-type="jpeg" data-w="1080"
                            style="vertical-align: middle; width: 641px !important; height: auto !important; visibility: visible !important;"
                            data-original-style="vertical-align: middle;width: 100%;" data-index="2"
                            src="@/assets/HY20240724/1.png" _width="100%" crossorigin="anonymous" alt="图片"
                            data-fail="0">
                        </section>
                      </section>
                    </section>
                  </section>
                  <section style="font-size: 18px; padding-right: 15px; padding-left: 15px; visibility: visible;">
                    <p style="text-wrap: wrap; visibility: visible;"><br style="visibility: visible;"></p>
                    <p style="text-wrap: wrap; visibility: visible;">
                      新医大一附院党委副书记、院长陆晨教授在学习班开幕式致辞中表示，新医大一附院作为自治区放射质控中心挂靠单位，多年来致力于推动全疆放射质控，本次学习班基于放射质控，为大家提供了一个学习、交流、对话的平台，希望大家能在此次学习班中学有所获。
                    </p>
                    <p style="text-wrap: wrap;"><br></p>
                    <p style="text-wrap: wrap;">
                      新疆医院协会会长温浩教授出席学习班开幕式并表示，精准医学，影像先行，影像学科作为平台学科，在各学科的发展中发挥着重要作用。希望本次学习班能够助力影像技术及影像诊断的标准化、规范化，促进我区医学影像学科的发展。
                    </p>
                    <p style="text-wrap: wrap;"><br></p>
                  </section>
                  <section style="margin: 0.5em auto;text-align: center;">
                    <section
                      style="border-radius: 1.5em;display: inline-block;line-height: 0;width: 90%;height: auto;overflow: hidden;">
                      <img class="rich_pages wxw-img" data-imgfileid="502734453" data-ratio="0.43148148148148147"
                        data-s="300,640" data-type="png" data-w="1080"
                        style="vertical-align: middle; width: 609.297px !important; height: auto !important; visibility: visible !important;"
                        data-original-style="vertical-align: middle;width: 100%;" data-index="3"
                        src="@/assets/HY20240724/2.png" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0">
                    </section>
                  </section>
                  <section style="font-size: 18px;padding-right: 15px;padding-left: 15px;">
                    <p style="text-wrap: wrap;"><br></p>
                    <p style="text-wrap: wrap;">
                      国家放射影像专业医疗质量控制中心专家委员会副主任委员、北京协和医院放射科副主任薛华丹教授的讲座为本次培训拉开序幕，她传达了国家放射质控中心工作要求，并就国家卫生健康委办公厅关于印发急诊医学等6个专业医疗质量控制指标（2024年版）的通知（国卫办医政函〔2024〕150号），针对放射影像专业的质量控制指标，进行了深入的传达与解读。薛华丹教授还就“急诊放射影像检查报告2小时完成率”和“放射影像危急值10分钟内通报完成率”等指标，积极回应专家及学员的提问，并现场展开了热烈讨论，提出了富有建设性的意见。
                    </p>
                    <p style="text-wrap: wrap;"><br></p>
                  </section>
                  <section style="margin: 0.5em auto;text-align: center;">
                    <section
                      style="border-radius: 1.5em;display: inline-block;line-height: 0;width: 90%;height: auto;overflow: hidden;">
                      <img class="rich_pages wxw-img" data-imgfileid="502734454" data-ratio="0.5608938547486033"
                        data-s="300,640" data-type="png" data-w="895"
                        style="vertical-align: middle; width: 609.297px !important; height: auto !important; visibility: visible !important;"
                        data-original-style="vertical-align: middle;width: 100%;" data-index="4"
                        src="@/assets/HY20240724/3.png" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0">
                    </section>
                  </section>
                  <section style="font-size: 18px;padding-right: 15px;padding-left: 15px;">
                    <p style="text-wrap: wrap;"><br></p>
                    <p style="text-wrap: wrap;">
                      自治区放射质控中心副主任、新医大一附院影像中心名誉主任刘文亚教授就影像危急值相关质控问题进行授课，她重点强调了影像危急值的目录及医院管理办法在质控工作中的重要性，认为不同医院危急值的范畴略有不同，建议各地州及各家单位参照国家指标，制定适应本院的危急值标准。
                    </p>
                    <p style="text-wrap: wrap;"><br></p>
                  </section>
                  <section
                    style="margin: 10px 0%;text-align: left;justify-content: flex-start;display: flex;flex-flow: row nowrap;">
                    <section
                      style="display: inline-block;vertical-align: middle;width: 50%;padding-right: 5px;align-self: center;flex: 0 0 auto;">
                      <section style="text-align: center;margin-right: 0%;margin-left: 0%;line-height: 0;">
                        <section style="vertical-align: middle;display: inline-block;line-height: 0;"><img
                            class="rich_pages wxw-img" data-imgfileid="502734451" data-ratio="0.6699029126213593"
                            data-s="300,640" data-type="png" data-w="412"
                            style="vertical-align: middle; width: 333.5px !important; height: auto !important; visibility: visible !important;"
                            data-original-style="vertical-align: middle;width: 100%;" data-index="5"
                            src="@/assets/HY20240724/4.png" _width="100%" crossorigin="anonymous" alt="图片"
                            data-fail="0"></section>
                      </section>
                    </section>
                    <section
                      style="display: inline-block;vertical-align: middle;width: 50%;padding-left: 5px;align-self: center;flex: 0 0 auto;">
                      <section style="text-align: center;margin-right: 0%;margin-left: 0%;line-height: 0;">
                        <section style="vertical-align: middle;display: inline-block;line-height: 0;"><img
                            class="rich_pages wxw-img" data-imgfileid="502734459" data-ratio="0.6674757281553398"
                            data-s="300,640" data-type="png" data-w="412"
                            style="vertical-align: middle; width: 333.5px !important; height: auto !important; visibility: visible !important;"
                            data-original-style="vertical-align: middle;width: 100%;" data-index="6"
                            src="@/assets/HY20240724/5.png" _width="100%" crossorigin="anonymous" alt="图片"
                            data-fail="0"></section>
                      </section>
                    </section>
                  </section>
                  <section
                    style="margin: 10px 0%;text-align: left;justify-content: flex-start;display: flex;flex-flow: row nowrap;">
                    <section
                      style="display: inline-block;vertical-align: middle;width: 50%;padding-right: 5px;align-self: center;flex: 0 0 auto;">
                      <section style="text-align: center;margin-right: 0%;margin-left: 0%;line-height: 0;">
                        <section style="vertical-align: middle;display: inline-block;line-height: 0;"><img
                            data-imgfileid="502734457" data-ratio="0.6666666666666666" data-s="300,640" data-type="png"
                            data-w="630"
                            style="vertical-align: middle; width: 333.5px !important; height: auto !important; visibility: visible !important;"
                            data-original-style="vertical-align: middle;width: 100%;" data-index="7"
                            src="@/assets/HY20240724/6.png" class="" _width="100%" crossorigin="anonymous" alt="图片"
                            data-fail="0"></section>
                      </section>
                    </section>
                    <section
                      style="display: inline-block;vertical-align: middle;width: 50%;padding-left: 5px;align-self: center;flex: 0 0 auto;">
                      <section style="text-align: center;margin-right: 0%;margin-left: 0%;line-height: 0;">
                        <section style="vertical-align: middle;display: inline-block;line-height: 0;"><img
                            class="rich_pages wxw-img" data-imgfileid="502734455" data-ratio="0.6666666666666666"
                            data-s="300,640" data-type="jpeg" data-w="1080"
                            style="vertical-align: middle; width: 333.5px !important; height: auto !important; visibility: visible !important;"
                            data-original-style="vertical-align: middle;width: 100%;" data-index="8"
                            src="@/assets/HY20240724/7.png" _width="100%" crossorigin="anonymous" alt="图片"
                            data-fail="0"></section>
                      </section>
                    </section>
                  </section>
                  <section
                    style="margin: 10px 0%;text-align: left;justify-content: flex-start;display: flex;flex-flow: row nowrap;">
                    <section
                      style="display: inline-block;vertical-align: middle;width: 50%;padding-right: 5px;align-self: center;flex: 0 0 auto;">
                      <section style="text-align: center;margin-right: 0%;margin-left: 0%;line-height: 0;">
                        <section style="vertical-align: middle;display: inline-block;line-height: 0;"><img
                            data-imgfileid="502734456" data-ratio="0.6675925925925926" data-s="300,640" data-type="jpeg"
                            data-w="1080"
                            style="vertical-align: middle; width: 333.5px !important; height: auto !important; visibility: visible !important;"
                            data-original-style="vertical-align: middle;width: 100%;" data-index="9"
                            src="@/assets/HY20240724/8.png" class="" _width="100%" crossorigin="anonymous" alt="图片"
                            data-fail="0"></section>
                      </section>
                    </section>
                    <section
                      style="display: inline-block;vertical-align: middle;width: 50%;padding-left: 5px;align-self: center;flex: 0 0 auto;">
                      <section style="text-align: center;margin-right: 0%;margin-left: 0%;line-height: 0;">
                        <section style="vertical-align: middle;display: inline-block;line-height: 0;"><img
                            class="rich_pages wxw-img" data-imgfileid="502734458" data-ratio="0.6675925925925926"
                            data-s="300,640" data-type="jpeg" data-w="1080"
                            style="vertical-align: middle; width: 333.5px !important; height: auto !important; visibility: visible !important;"
                            data-original-style="vertical-align: middle;width: 100%;" data-index="10"
                            src="@/assets/HY20240724/9.png" _width="100%" crossorigin="anonymous" alt="图片"
                            data-fail="0"></section>
                      </section>
                    </section>
                  </section>
                  <section style="font-size: 18px;padding-right: 15px;padding-left: 15px;">
                    <p style="text-wrap: wrap;"><br></p>
                    <p style="text-wrap: wrap;">
                      学习班上，西安交通大学第一附属医院郭佑民教授，空军军医大学西京医院郑敏文教授，郑州大学第一附属医院张永高教授，空军军医大学西京医院文娣娣教授，新疆医科大学第一附属医院王云玲教授、邢艳教授、赵圆教授，新疆维吾尔自治区中医医院栾丽主任，新疆维吾尔自治区人民医院火忠主任，新疆乌鲁木齐市友谊医院齐玲俊主任，新疆心脑血管病医院王善涛主任等疆内外知名专家分别围绕放射影像质控问题进行授课，专题涉及急腹症、胸部、心脏、乳腺等多个方面。课后学员们积极讨论、踊跃发言、主动提问，分享了各单位的实际情况和遇到的问题，相互交流心得。
                    </p>
                    <p style="text-wrap: wrap;"><br></p>
                  </section>
                  <section style="margin: 0.5em auto;text-align: center;">
                    <section
                      style="border-radius: 1.5em;display: inline-block;line-height: 0;width: 90%;height: auto;overflow: hidden;">
                      <img class="rich_pages wxw-img" data-imgfileid="502734462" data-ratio="0.6018518518518519"
                        data-s="300,640" data-type="png" data-w="1080"
                        style="vertical-align: middle; width: 609.297px !important; height: auto !important; visibility: visible !important;"
                        data-original-style="vertical-align: middle;width: 100%;" data-index="11"
                        src="@/assets/HY20240724/10.png" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0">
                    </section>
                  </section>
                  <section style="font-size: 18px;padding-right: 15px;padding-left: 15px;">
                    <p style="text-wrap: wrap;"><br></p>
                    <p style="text-wrap: wrap;">
                      学习班还安排了CT和MR扫描关键技术及质控实操培训，参训人员直观地了解新技术的应用及其质量控制要求，进一步提升了医学影像工作者对质量控制的认识和管理工作水平。</p>
                    <p style="text-wrap: wrap;"><br></p>
                  </section>
                  <section style="margin: 0.5em auto;text-align: center;">
                    <section
                      style="border-radius: 1.5em;display: inline-block;line-height: 0;width: 90%;height: auto;overflow: hidden;">
                      <img class="rich_pages wxw-img" data-imgfileid="502734461" data-ratio="0.433502538071066"
                        data-s="300,640" data-type="png" data-w="985"
                        style="vertical-align: middle; width: 609.297px !important; height: auto !important; visibility: visible !important;"
                        data-original-style="vertical-align: middle;width: 100%;" data-index="12"
                        src="@/assets/HY20240724/11.png" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0">
                    </section>
                  </section>
                  <section style="font-size: 18px;padding-right: 15px;padding-left: 15px;">
                    <p style="text-wrap: wrap;"><br></p>
                    <p style="text-wrap: wrap;">
                      本次自治区放射质控中心工作会议及学习班旨在增强我区医学影像专业质控的培训和指导，有效促进各地州县市医疗机构质控中心构建与质量管理工作的落实，切实提升质控管理的科学化与精细化水平，保障并推动我区医学影像专业的学科发展，为各族群众提供优质高效的医学影像服务奠定坚实基础。
                    </p>
                    <p style="text-wrap: wrap;"><br></p>
                    <p style="text-wrap: wrap;">
                      会议及学习班由自治区放射质控中心主任、新疆医科大学第一附属医院影像中心副主任邢艳教授主持，来自全疆近50家医疗单位的200余名医学影像专业工作者共同参与。</p>
                    <p style="text-wrap: wrap;"><br></p>
                  </section>
                  
                </section>
              </div>


            </div>
            <div id="js_tags_preview_toast" class="article-tag__error-tips" style="display: none;">预览时标签不可点</div>

            <div id="content_bottom_area"><!----><!----><!---->
              <div data-len="0" class="article-tag__list" style="display: none;">
                <div class="article-tags"></div>
              </div>
              <div class="appmsg_card_context album_read_card" style="display: none;"><!----></div>
              <div class="rich_media_tool_area" style="display: none;">
                <div class="rich_media_tool__wrp">
                  <div id="js_content_end" class="rich_media_tool">
                    <div class="rich_media_info weui-flex policy_tips tips_global_primary"><!----></div>
                    <div class="rich_media_info weui-flex"><!----><!----><!----></div>
                    <div id="js_bottom_opr_right" class="weui-flex sns_opr_area" style="display: none;"><!----></div>
                  </div><!----><!---->
                </div>
              </div>
            </div>


            <div id="js_temp_bottom_area" class="rich_media_tool_area">

              <div class="rich_media_tool__wrp">
                <div class="rich_media_tool">

                  <div class="rich_media_info weui-flex policy_tips tips_global_primary">
                  </div>

                  <div class="rich_media_info weui-flex ">



                  </div>
                </div>
              </div>

            </div>



          </div>
        </div>



      </div>


    </div>
  </div>
</template>

<script>
export default {
  name: "HY20240724",
  data() {
    return {
      yourExternalPageURL: "https://mp.weixin.qq.com/s/n23cjKPgFAuQ-S8-sO5b3g",
    }
  },
 

}
</script>

<style scoped>
.card_box {
  width: 350px;
  cursor: pointer;
}

.card_img {
  width: 300px;
}

.rich_media_area_primary_inner {
  max-width: 677px;
  margin: 0 auto;
}

.rich_media_meta_list {
  margin-bottom: 22px;
  line-height: 20px;
  font-size: 0;
  word-wrap: break-word;
  hyphens: auto;
}

.rich_media_meta {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 10px 0;
  font-size: 15px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  color: #576b95;
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rich_media_meta_list em {
  font-style: normal;
}

.rich_media_meta_text {
  color: rgba(255, 255, 255, .35);
  color: rgba(0, 0, 0, .3);
}

.rich_media_title {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
}

#js_article {
  background-color: #fff;
}
p{
  text-indent: 2em;
}
.rich_media_content {
  text-align: justify;
  letter-spacing: 1px;
  line-height: 1.5;
}
</style>